<template>
  <div>

    <v-card
      elevation="0"
      class=""
    >
      <v-card-title class="pa-3">
        <v-icon class="mr-3">{{icons.mdiImageOutline}}</v-icon>
        Gallerie
        <v-spacer></v-spacer>
        <v-btn
          to="/gallery"
          small text
          color="primary"
        >
          <v-icon>{{ icons.mdiChevronRight }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-actions
        v-if="loader.reloadchapter"
        class="text-center"
      >
        <v-btn
          class="ma-auto"
          color="primary"
          @click="init()"
        >
          Recharger
        </v-btn>
      </v-card-actions>

        <v-sheet
          v-if="loader.loading"
          class="mx-auto"
        >
          <v-slide-group
            multiple
            :show-arrows="showarrow"
          >
            <v-slide-item
              v-for="n in 9"
              :key="'lnew'+n"
              v-slot="{ }"
            >
              <v-card
                :color="`grey darken-2 `"
                class="pa-3"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  :height="slider_height.news"
                  :width="columns"
                  type="card"
                ></v-skeleton-loader>
              </v-card>
            </v-slide-item>

            <!--          <v-slide-item v-else
                                    v-for="(item, index) in newchapters"
                                    :key="'new-'+index"
                                    v-slot="{ }"
                      >
                      </v-slide-item>-->
          </v-slide-group>
        </v-sheet>
        <v-sheet
          v-else
          class="mx-auto "
        >
          <v-slide-group
            multiple
            :show-arrows="showarrow"
          >
            <v-slide-item
              v-for="(item, index) in postimages"
              :key="'new-'+index"
              class=" ml-3"
              v-slot="{ }"
            >
              <v-card
                :to="'gallery'"
                     class="ma-3 mx-auto"
                :width="columns"
            >
              <v-img
                :src="item.uploaddir+'270_'+item.image"
                :lazy-src="require('@/assets/images/holder.jpg')"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

    </v-card>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiImageOutline,
  mdiChevronRight,
  mdiEyeOutline,
  mdiEyeOffOutline, mdiCartOutline, mdiClose,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import PostSummaryComponent from "@/components/PostSummaryComponent.vue";

export default {
  components: {PostSummaryComponent},
  setup() {
    const $vuetify = getVuetify()

    const items = ref([])
    const postimages = ref([])

    const loader = ref({
      news: true,
      oneshotnews: true,
      products: true,
      reloadprod: false,
      reloadchapter: false,
      oneshotreloadchapter: false,
    })
    const mcs = ref([])

    // const user = Drequest.getUser()

    const init = () => {
      loader.value.reloadchapter = false
      loader.value.reloadprod = false

      Drequest.api('lazyloading.dv-image?dfilters=on&next=1&per_page=6&folder.slug:eq=gallery&drand=1')// &user_id=269&subscription_id=1
        .get(response => {
          console.log(response)
          loader.value.news = false
          postimages.value = response.listEntity
        })
        .fail(e => {
          loader.value.news = false
          loader.value.reloadchapter = true
          console.log(e)
        })

    }
    init()

    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 300
      }
      if ($vuetify.breakpoint.xs) {
        return 210
      }

      return 360
    })

    const showarrow = computed(() => {
      if ($vuetify.breakpoint.md || $vuetify.breakpoint.lg) {
        return true
      }

      return false
    })

    return {
      init,

      columns,
      loader,
      items,
      postimages,
      showarrow,

      icons: {
        mdiImageOutline,
        mdiCartOutline,
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiChevronRight
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
